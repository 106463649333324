<template>
  <div id="mbti-result" class="section">
    <div class="jumbotron text-right">
      <div class="container">
        <h2>Tipe kepribadianmu adalah</h2>
        <h1>INFJ</h1>
      </div>
    </div>
    <div class="container">
      <div class="row">
        <div class="col-lg-7">
          <p>
            Seseorang yang berkepribadian INFJ sering disebut the counselor atau penasihat.  Hanya ada 1-3% populasi  INFJ di dunia. Dimana menurut dimensinya INFJ berarti Introversion atau seseorang yang tidak suka akan hubungan dan dunia luar. Intuition dimana mereka membicarakan hal yang bersifat masa depand an belum jelas atau abstrak. Feeling yaitu mereka memutuskan berbagai hal dengan perasaan dan menyangkut pribadi serta Judging yang mana mereka mengutamakan rencana baru melakukan hal selanjutnya. 
          </p>
          <p>
            Ciri kepribadian INFJ adalah:
            <ul>
              <li> Berorientasi pada masa depan dan berpikiran secara kompleks serta mendalam.</li>
              <li> Berkomitmen, penuh ide, perfeksionis, dan juga pendiam. Sayangnya terkadang mereka sering mengekspresikan hal yang dianggap tidak ramah dan berempati.</li>
              <li> Tak suka berurusan dengan hal yang rinci dan juga sulit. mengenali apa potensi diri mereka. </li>
            </ul>
          </p>
          <p>Saran dan pekerjaan yang cocok:
            <ul>
              <li>
                Seimbangkan pandangan anda jangan terlalu melihat hal ke arah negative atau buruk ‘’bersabar dan rilekslah, dan jangan paksakan apa yang menjadi hal yang tidak bisa anda kerjakan
              </li>
              <li>
                Pekerjaan yang cocok adalah penasihat, Psikolog, pekerja sosial, dokter, tenaga kesehatan, pemerhati anak dan pemasaran
              </li>
            </ul>
          </p>
          <p>
            Asmara untuk INFJ adalah mereka yang memiliki kepribadian ENFP atau ENTP yang mungkin lebih bisa mengimbangi pribadi INFJ.
          </p>
        </div>
        <div class="col-lg-5">
          <img src="../../../assets/images/mbti/INFJ.png" alt="" class="illustration">
          <div class="media">
            <img src="../../../assets/images/mbti/Introversion.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="introversion">Introversion</h5>
              <p>cenderung pendiam dan suka menyendiri</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Intution.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="intution">Intution</h5>
              <p>lebih fokus terhadap gambaran masa depan</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Feeling.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="feeling">Feeling</h5>
              <p>membuat keputusan berdasarkan logika</p>
            </div>
          </div>
          <div class="media">
            <img src="../../../assets/images/mbti/Judgement.png" class="mr-3" alt="">
            <div class="media-body">
              <h5 class="judgement">Judgement</h5>
              <p>cenderung membiarkan pilihan tetap terbuka</p>
            </div>
          </div>
        </div>
      </div>
      <ShareButton :result="result" />
    </div>
  </div>
</template>

<script>
export default {
  name: "INFJ",
  components: {
    ShareButton: () => import('@/components/ShareButton'),
  },
  data() {
    return {
      result: {
        name: 'INFJ',
        slug: 'infj'
      }
    }
  }
};
</script>

<style scoped>

</style>
